// import $ from "jquery";
import React, { Component, createRef, useEffect, useState } from "react";
import ReactDOM from "react-dom";
// import Select from 'react-select'
// import SelectSearch from 'react-select-search';
// import 'react-select-search/style.css'

// window.jQuery = $;

// window.$ = $;

require("jquery-ui-sortable");
require("formBuilder");

const auth =
  "Bearer patESh3tXxmNRO0zb.36654bc4e386475115a68b092725fc738c3222087c1a71b50c11a5a713cffb2b";

async function fetchAirTable(endpoint) {
  var myHeaders = new Headers();
  myHeaders.append("Authorization", auth);

  var requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };
  const recoreds = [];
  let res;
  do {
    res = await fetch(
      "https://api.airtable.com/v0/appXbAw5XSQ7WhNkQ" +
        endpoint +
        (res?.offset ? `?offset=${res.offset}` : ""),
      requestOptions
    )
      .then((response) => response.json())
      .catch((error) => console.log("error", error));
    recoreds.push(...res.records);
  } while (res.offset);
  return recoreds;
}
async function createCustomer(obj) {
  var myHeaders = new Headers();
  myHeaders.append("Authorization", auth);
  myHeaders.append("Content-Type", "application/json");

  var raw = JSON.stringify({
    records: [
      {
        fields: {
          Phone: obj.Phone,
          Name: obj.Name,
          Email: obj.Email,
        },
      },
    ],
  });

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  return fetch(
    "https://api.airtable.com/v0/appXbAw5XSQ7WhNkQ/Customers",
    requestOptions
  )
    .then((response) => response.json())
    .catch((error) => console.log("error", error));
}
async function createLineItems(obj) {
  var myHeaders = new Headers();
  myHeaders.append("Authorization", auth);
  myHeaders.append("Content-Type", "application/json");
  const post = {
    records: [
      // {
      //   "fields": {
      //     "Product": [
      //       "recXd599ia16b6CHb"
      //     ],
      //     "Quantity": 15
      //   }
      // }
    ],
  };
  for (let [k, v] of Object.entries(obj)) {
    post.records.push({
      fields: {
        Product: [k],
        Quantity: parseFloat(v),
      },
    });
  }
  var raw = JSON.stringify(post);

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  return fetch(
    "https://api.airtable.com/v0/appXbAw5XSQ7WhNkQ/Line Items",
    requestOptions
  )
    .then((response) => response.json())
    .catch((error) => console.log("error", error));
}
async function createOrder(obj, items) {
  var myHeaders = new Headers();
  myHeaders.append("Authorization", auth);
  myHeaders.append("Content-Type", "application/json");

  var raw = JSON.stringify({
    records: [
      {
        fields: {
          Location: obj.Location,
          "Line Items": items.records.map((e) => e.id),
          Status: "Upcoming",
          Customer: [obj.customer],
          Specialties: obj.Specialties,
          Date: obj.Date,
          Address: obj.Address,
          "Address Line 2": obj.Address_Line_2,
          City: obj.City,
          State: obj.State,
          "Zip Code": obj.Zip,
          Owner: {
            email: obj.Owner,
          },
          "Drop off Name": obj.DrodOff,
          Notes: obj.Notes,
          Discount: parseFloat(obj.Discount),
          "Delivery Surcharge": parseFloat(obj.Delivery_Surcharge),
          "Other Surcharge": parseFloat(obj.Other_Surcharge),
        },
      },
    ],
  });

  // console.log(raw);

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  return fetch(
    "https://api.airtable.com/v0/appXbAw5XSQ7WhNkQ/Orders",
    requestOptions
  )
    .then((response) => response.json())
    .catch((error) => console.log("error", error));
}
let done2=false
function App() {
  const [customers, setCustomers] = useState([]);
  const [items, setItems] = useState([]);
  const [formData, setFormData] = useState([]);
  const [selectedItems, setSelectedItems] = useState({});
  const [loading, setLoading] = useState(false);
  const [done, setDone] = useState(false);
  useEffect(() => {
    fetchAirTable("/Customers").then((e) => setCustomers(e));
    fetchAirTable("/Products").then((e) => setItems(e));
  }, []);

  async function onSubmit(e) {
    setLoading(true);
    e.preventDefault();
    if (!formData.customer) {
      formData.customer = await createCustomer(formData);
      formData.customer = formData.customer.records[0].id;
    }
    const lineItems = await createLineItems(selectedItems);
    await createOrder(formData, lineItems);
    setDone(true);
  }
  useEffect(
    (e) => {
      if(!done2&&customers.length){
        // debugger
        window
        .$("select")
        .select2()
        .on("select2:select", function (e) {
          if (e.target.name == "Specialties") {
            setFormData((b) => ({
              ...b,
              [e.target.name]: [...e.target.options]
                .filter((o) => o.selected)
                .map((o) => o.value),
              }));
          } else if (e.target.name == "cus1") {
            const cust = customers.find((b) => b.id == e.target.value);
            // debugger
            setFormData((d) => ({
              ...d,
              customer: cust.id,
              Name: cust.fields.Name,
              Email: cust.fields.Email,
              Phone: cust.fields.Phone,
            }));
          } else {
            setFormData((b) => ({ ...b, [e.target.name]: e.target.value }));
          }
        });
        done2 = true
      }
    },
    [customers]
  );
  if (done) {
    return (
      <div class="orderDone">
        <h1>Success, refresh your page to start over</h1>
        <a href=".">Add another order</a>
      </div>
    );
  }
  if (loading) {
    return "Loading...";
  }

  return (
    <div style={{ margin: "auto", maxWidth: 700 }}>
      <img></img>
      <form id="rendered-form" onSubmit={onSubmit}>
        <div className="rendered-form">
          <select name="cus1">
            {customers.map((e) => (
              <option value={e.id}>
                {e.fields.Name} - {e.fields.Phone}
              </option>
            ))}
          </select>

          <hr />

          <h3>Customer Info</h3>

          <div className="formbuilder-text form-group field-Name">
            <label htmlFor="Name" className="formbuilder-text-label">
              Customer Name<span className="formbuilder-required">*</span>
            </label>
            <input
              type="text"
              className="form-control"
              value={formData.Name}
              onChange={(e) =>
                setFormData((b) => ({ ...b, [e.target.name]: e.target.value }))
              }
              name="Name"
              id="Name"
              required="required"
              aria-required="true"
            />
          </div>
          <div className="formbuilder-text form-group field-Email">
            <label htmlFor="Email" className="formbuilder-text-label">
              Customer Email
            </label>
            <input
              type="email"
              className="form-control"
              name="Email"
              id="Email"
              onChange={(e) =>
                setFormData((b) => ({ ...b, [e.target.name]: e.target.value }))
              }
              value={formData.Email}
            />
          </div>
          <div className="formbuilder-text form-group field-Phone">
            <label htmlFor="Phone" className="formbuilder-text-label">
              Customer Phone
            </label>
            <input
              type="tel"
              className="form-control"
              name="Phone"
              id="Phone"
              value={formData.Phone}
              onChange={(e) =>
                setFormData((b) => ({ ...b, [e.target.name]: e.target.value }))
              }
            />
          </div>

          <h3>Pickup/Delivary Info</h3>

          <div className="formbuilder-date form-group field-Date">
            <label htmlFor="Date" className="formbuilder-date-label">
              Date
            </label>
            <input
              type="datetime-local"
              className="form-control"
              name="Date"
              vlaue={formData.Data}
              onChange={(e) =>
                setFormData((b) => ({ ...b, [e.target.name]: e.target.value }))
              }
              id="Date"
            />
          </div>

          <div className="formbuilder-autocomplete form-group field-Location">
            <label
              htmlFor="Location"
              className="formbuilder-autocomplete-label"
            >
              Pickup Location<span className="formbuilder-required">*</span>
            </label>
            <select name="Location" value={formData.Location}>
              <option value="" selected disabled hidden>
                Select an Option
              </option>
              <option value="Pickup">Pickup</option>
              <option value="BP">BP</option>
              <option value="Monsey">Monsey</option>
              <option value="Williamsburg">Williamsburg</option>
              <option value="Lakewood">Lakewood</option>
              <option value="Monroe">Monroe</option>
              <option value="Flatbush">Flatbush</option>
              <option value="Teaneck">Teaneck</option>
              <option value="Uber">Uber</option>
              <option value="Custom Delivery">Custom Delivery</option>
            </select>
          </div>

          <div className="formbuilder-autocomplete form-group field-Owner">
            <label htmlFor="Owner" className="formbuilder-autocomplete-label">
              Owner<span className="formbuilder-required">*</span>
            </label>
            <select
              name="Owner"
              onChange={(e) =>
                setFormData((b) => ({ ...b, [e.target.name]: e.target.value }))
              }
              value={formData.Owner}
            >
              <option value="" selected disabled hidden>
                Select an Option
              </option>
              <option value="smokedstyle1@gmail.com">Mandy</option>
              <option value="smokedstylezindy@gmail.com">Zindy</option>
              <option value="getzyw89@gmail.com">Getzy</option>
            </select>
          </div>

          <div className="formbuilder-textarea form-group field-Notes">
            <label htmlFor="Notes" className="formbuilder-textarea-label">
              Notes
            </label>
            <textarea
              className="form-control"
              name="Notes"
              rows="2"
              value={formData.Notes}
              onChange={(e) =>
                setFormData((b) => ({ ...b, [e.target.name]: e.target.value }))
              }
              id="Notes"
            ></textarea>
          </div>

          <div className="formbuilder-text form-group field-DrodOff">
            <label htmlFor="DrodOff" className="formbuilder-text-label">
              Drop Off Name
            </label>
            <input
              type="text"
              className="form-control"
              name="DrodOff"
              value={formData.DrodOff}
              onChange={(e) =>
                setFormData((b) => ({ ...b, [e.target.name]: e.target.value }))
              }
              id="DrodOff"
            />
          </div>

          <div className="formbuilder-text form-group field-Address">
            <label htmlFor="Address" className="formbuilder-text-label">
              Address
            </label>
            <input
              type="text"
              className="form-control"
              name="Address"
              value={formData.Address}
              onChange={(e) =>
                setFormData((b) => ({ ...b, [e.target.name]: e.target.value }))
              }
              id="Address"
            />
          </div>
          <div className="formbuilder-text form-group field-Address-Line-2">
            <label htmlFor="Address_Line_2" className="formbuilder-text-label">
              Address Line 2
            </label>
            <input
              type="text"
              className="form-control"
              vlaue={formData.Address_Line_2}
              onChange={(e) =>
                setFormData((b) => ({ ...b, [e.target.name]: e.target.value }))
              }
              name="Address_Line_2"
              id="Address_Line_2"
            />
          </div>
          <div className="formbuilder-text form-group field-City">
            <label htmlFor="City" className="formbuilder-text-label">
              City
            </label>
            <input
              type="text"
              className="form-control"
              name="City"
              value={formData.City}
              onChange={(e) =>
                setFormData((b) => ({ ...b, [e.target.name]: e.target.value }))
              }
              id="City"
            />
          </div>
          <div className="formbuilder-text form-group field-State">
            <label htmlFor="State" className="formbuilder-text-label">
              State
            </label>
            <input
              type="text"
              className="form-control"
              name="State"
              value={formData.State}
              onChange={(e) =>
                setFormData((b) => ({ ...b, [e.target.name]: e.target.value }))
              }
              id="State"
            />
          </div>
          <div className="formbuilder-text form-group field-Zip">
            <label htmlFor="Zip" className="formbuilder-text-label">
              Zip
            </label>
            <input
              type="text"
              className="form-control"
              name="Zip"
              id="Zip"
              value={formData.Zip}
              onChange={(e) =>
                setFormData((b) => ({ ...b, [e.target.name]: e.target.value }))
              }
            />
          </div>
          <br />

          <br />
          <select name="categ">
            <option value="Smoked">Smoked</option>
            <option value="Non-Smoked">Non-Smoked</option>
            <option value="Jerky">Jerky</option>
            <option value="Other">Other</option>
            <option value="Boards">Boards</option>
          </select>
          <input
            placeholder="Search Items..."
            value={formData.itemSearch}
            name="itemSearch"
            onChange={(e) =>
              setFormData((b) => ({ ...b, [e.target.name]: e.target.value }))
            }
          />
          {items
            .filter((e) =>
              formData.categ ? e.fields.Category == formData.categ : true
            )
            .filter((e) =>
              formData.itemSearch
                ? e.fields.Name.toLowerCase().includes(formData.itemSearch)
                : true
            )
            .map((e) => (
              <div class="itemListItem">
                {/* <h4>{e.fields.Category}</h4><br/> */}
                <h5>
                  {e.fields.Name} <strong>${e.fields.Price}</strong>
                </h5>
                <br />
                <input
                  type={"number"}
                  step="any"
                  min="0"
                  value={selectedItems[e.id] || 0}
                  onChange={(d) =>
                    setSelectedItems((b) => ({ ...b, [e.id]: d.target.value }))
                  }
                />
              </div>
            ))}

          <br />
          <br />
          <br />

          <div className="formbuilder-select form-group field-Specialties">
            <label htmlFor="Specialties" className="formbuilder-select-label">
              Specialties
            </label>
            <select
              className="form-control"
              name="Specialties"
              value={formData.Specialties}
              multiple="multiple"
              id="Specialties"
            >
              <option value="Wine on the Board" id="Specialties-0">
                Wine on the Board
              </option>
              <option value="Wine on the Side" id="Specialties-1">
                Wine on the Side
              </option>
              <option value="Sticker" id="Specialties-2">
                Sticker
              </option>
              <option value="Card" id="Specialties-3">
                Card
              </option>
              <option value="Custom Picture" id="Specialties-4">
                Custom Picture
              </option>
              <option value="Whiskey" id="Specialties-5">
                Whiskey
              </option>
              <option value="Other" id="Specialties-6">
                Other
              </option>
            </select>
          </div>

          <div className="formbuilder-text form-group field-Delivery_Surcharge">
            <label
              htmlFor="Delivery_Surcharge"
              className="formbuilder-text-label"
            >
              Delivery Surcharge
            </label>
            <input
              type="number"
              min="0.00"
              step="0.01"
              className="form-control"
              name="Delivery_Surcharge"
              value={formData.Delivery_Surcharge}
              onChange={(e) =>
                setFormData((b) => ({ ...b, [e.target.name]: e.target.value }))
              }
              id="Delivery_Surcharge"
            />
          </div>

          <div className="formbuilder-text form-group field-Other_Surcharge">
            <label htmlFor="Other_Surcharge" className="formbuilder-text-label">
              Other Surcharge
            </label>
            <input
              type="number"
              min="0.00"
              step="0.01"
              className="form-control"
              name="Other_Surcharge"
              value={formData.Other_Surcharge}
              onChange={(e) =>
                setFormData((b) => ({ ...b, [e.target.name]: e.target.value }))
              }
              id="Other_Surcharge"
            />
          </div>

          <div className="formbuilder-text form-group field-Discount">
            <label htmlFor="Discount" className="formbuilder-text-label">
              Discount
            </label>
            <input
              type="number"
              min="0.00"
              step="0.01"
              className="form-control"
              name="Discount"
              value={formData.Discount}
              onChange={(e) =>
                setFormData((b) => ({ ...b, [e.target.name]: e.target.value }))
              }
              id="Discount"
            />
          </div>

          <button>Submit</button>
        </div>
      </form>
    </div>
  );
}

export default App;
